<template>
  <div class="user-profile">
    <navbar />

    <van-form>
      <van-field label="头像" placeholder="请选择头像">
        <template #input>
          <van-uploader
            v-if="userInfoLocal.avatar"
            v-model="userInfoLocal.avatar"
            :max-count="1"
            :after-read="uploadFile"
          />
        </template>
      </van-field>
      <van-field
        v-model="userInfoLocal.mobile"
        label="手机号码"
        placeholder="请输入手机号码"
      />
      <van-field
        v-if="showSms"
        v-model="userInfoLocal.sms_code"
        label="验证码"
        placeholder="请输入验证码"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            :disabled="smsLoading"
            @click="sendSms()"
            >{{ smsLoading ? smsCount : "获取验证码" }}</van-button
          >
        </template>
      </van-field>
      <van-field
        v-model="userInfoLocal.real_name"
        label="真实姓名"
        placeholder="请输入真实姓名"
      />
      <van-field label="性别" placeholder="请选择性别">
        <template #input>
          <van-radio-group
            v-model="userInfoLocal.sex"
            direction="horizontal"
            checked-color="#CA0A15"
          >
            <van-radio :name="1">男</van-radio>
            <van-radio :name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </van-form>
    <div style="margin: 16px;">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        color="#CA0A15"
        @click="submit"
        >提交</van-button
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  Dialog,
  CellGroup,
  Field,
  Button,
  Form,
  Radio,
  RadioGroup,
  Uploader
} from "vant";
import Navbar from "@/components/h5/Navbar";
import indexApis from "@/apis/index";
import { apiRoot } from "@/config";
import axios from "axios";

function pick(obj, props) {
  // Create new object
  var picked = {};

  // Loop through props and push to new object
  for (let prop of props) {
    picked[prop] = obj[prop];
  }

  // Return new object
  return picked;
}

export default {
  name: "UserCenter",
  components: {
    Navbar,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Button.name]: Button,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Uploader.name]: Uploader
    // PayDialog
  },
  data() {
    return {
      smsLoading: false,
      smsCount: 60,
      destTimestamp: 0,
      userInfoLocal: {
        mobile: "",
        sms_code: "",
        avatar: "",
        sex: "",
        real_name: ""
      }
    };
  },
  computed: {
    ...mapState(["userInfo", "vipIndex"]),
    userVip() {
      return (
        this.vipIndex.find(item => item.id === this.userInfo.vip_level) || {}
      );
    },
    showSms() {
      return this.userInfoLocal.mobile !== this.userInfo.mobile;
    },
    uploadData() {
      const auth = localStorage.getItem("auth") || "{}";
      const { token } = JSON.parse(auth);
      return { token };
    }
  },
  // created() {
  //   console.log("created");
  //   this.userInfoLocal = {
  //     ...this.userInfo,
  //     avatar: [{ url: this.userInfo.avatar, isImage: true }]
  //   };
  // },
  methods: {
    ...mapActions(["clearUserInfo", "fetchUserInfo"]),
    uploadFile(file) {
      const form = new FormData();
      form.append("avatar", file.file);
      form.append("token", this.uploadData.token);
      axios.post(`${apiRoot}/user/upload_avatar`, form).then(
        ({
          data: {
            data: { avatar_url }
          }
        }) => {
          this.userInfoLocal.avatar = [{ url: avatar_url, isImage: true }];
        }
      );
    },
    logout() {
      Dialog.confirm({
        title: "提示",
        message: "确认退出登陆吗?"
      }).then(() => {
        this.clearUserInfo();
        setTimeout(() => {
          location.reload();
        }, 500);
        // this.$router.replace("/h5/home");
      });
    },
    async sendSms() {
      if (this.smsLoading) return;
      let mobile = "";
      if (!this.userInfoLocal.mobile.trim()) return;
      mobile = this.userInfoLocal.mobile.trim();

      await indexApis.smsCode({ mobile: parseInt(mobile) });
      this.$toast("验证码发送成功");
      this.smsLoading = true;
      this.destTimestamp = Date.now() + 1000 * 60;
      this.smsCounter = setInterval(() => {
        if (Date.now() >= this.destTimestamp) {
          this.smsCounter && clearInterval(this.smsCounter);
          this.smsLoading = false;
          this.smsCount = 60;
          return;
        }
        this.smsCount = Math.round((this.destTimestamp - Date.now()) / 1000);
      }, 1000);
    },

    async submit() {
      const form = {
        ...this.userInfoLocal,
        avatar: this.userInfoLocal.avatar?.[0]?.url
      };
      await indexApis.updateProfile({
        ...form
      });
      this.fetchUserInfo();
      this.$toast("修改成功");
      this.visible = false;
    }
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        this.userInfoLocal = {
          ...pick(val, Object.keys(this.userInfoLocal)),
          avatar: [{ url: val.avatar, isImage: true }]
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile {
}
</style>
